export default [
  {
    header: "Navigation",
  },
  {
    title: "Assignments",
    route: "assistant-calendar",
    icon: "CheckSquareIcon",
  },
  {
    title: "Time Off",
    route: "assistant-timeoff",
    icon: "CheckCircleIcon",
  },
  {
    title: "Timesheets",
    route: "assistant-timesheet",
    icon: "GridIcon",
  },
];
