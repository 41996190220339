export default [
  {
    header: "Navigation",
  },
  {
    title: "Active Bookings",
    route: "admin-active-bookings",
    icon: "CheckSquareIcon",
  },
  {
    title: "Manage Availability",
    route: "admin-manage-availability",
    icon: "CalendarIcon",
  },
  {
    title: "Time Off",
    route: "admin-timeoff",
    icon: "ClockIcon",
  },
  {
    title: "Reporting",
    route: "admin-reporting",
    icon: "FileTextIcon",
  },
  {
    title: "Clients",
    route: "admin-clients",
    icon: "UserIcon",
  },
  {
    title: "User",
    route: "admin-users",
    icon: "UsersIcon",
  },
];
