/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from "./dashboard";
import appsAndPages from "./apps-and-pages";
import others from "./others";
import chartsAndMaps from "./charts-and-maps";
import uiElements from "./ui-elements";
import formAndTable from "./forms-and-table";

// Menu for assitant
import assistantMenu from "./assistant";

// Menu for admin
import adminMenu from "./admin";

// Menu for hospital
import hospitalMenu from "./hospital";

// Array of sections

export default () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  let menu = [];
  if (userData) {
    menu =
      userData.role === "admin"
        ? adminMenu
        : userData.role === "assistant"
        ? assistantMenu
        : userData.role === "manager"
        ? hospitalMenu
        : [];
  }
  return menu;
};
